<template>
  <div>
    <el-dialog
      title="关于我们"
      :visible.sync="visible"
      :before-close="dialogBeforeClose"
      width="80%">
    <editor-component
      :defaultContent="content"
      style="width:100%"
      @getContent="getContent"
      ref="courseContentComponent"
      :id="'courseContent'"
      :height="'300'"></editor-component>
    <div slot="footer" align="center">
        <el-button type="primary" @click="dialogSave">保存</el-button>
        <el-button @click="dialogBeforeClose">取消</el-button>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import EditorComponent from "@/components/editorComponent/index.vue";
export default {
  components: { EditorComponent },
  props: {
    contentData: Object,
  },
  data() {
    return {
      visible: false,
      flag: 0,
      content: '',
      strContent: ''
    };
  },
  methods: {
    dialogBeforeClose() {
      this.visible = false;
      this.content = ' ';
    },
    dialogSave() {
      this.$emit('closeContentData', this.strContent);
      this.visible = false;
    },
    getContent(data) {
      console.log(data);
      this.strContent = data;
    }
  },
  watch: {
    contentData(data) {
      this.visible = true;
      this.flag = data.flag;
      this.content = data.content;
    }
  }
};
</script>

<style lang="scss">

</style>
